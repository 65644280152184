import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import circleImg from "../../images/contact_info_circles.png";
import { emailIcon, locationIcon, phoneIcon } from "../../Util/Svgs";
import "./Contact.css";

const Contact = () => {
  return (
    <div className="contact_main">
      <Container>
        <h1>Need Assistance?</h1>
        <p>Feel free to ask us anything. We're just a message away.</p>

        <Row className="justify-content-between">
          <Col md={5}>
            <div className="contact_left_section">
              <h3>Contact Information</h3>
              <h5>Say something to start a live chat!</h5>

              <div className="contact_info">
                <Row>
                  <Col md={1} xs={1}>
                    {phoneIcon}
                  </Col>
                  <Col md={10} xs={10}>
                    <span>+92 21 111 252 575</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={1} xs={1}>
                    {emailIcon}
                  </Col>
                  <Col md={10} xs={10}>
                    <span>ajcl.khi@ajcl.net</span>
                  </Col>
                </Row>
                <Row>
                  <Col md={1} xs={1}>
                    {locationIcon}
                  </Col>
                  <Col md={10} xs={10}>
                    <span>
                      Hall No. 01, Lower Ground Floor, Plot No. 12-N, (Pakland
                      Square) Markaz G-8, Islamabad, Pakistan
                    </span>
                  </Col>
                </Row>
              </div>

              <img src={circleImg} alt="" id="circleImg" />
            </div>
          </Col>
          <Col md={6}>
            <div className="contact_form">
              <h3>Contact Us</h3>
              <h5>Got questions or feedback? Drop us a message!</h5>

              <form>
                <Row>
                  <Col md={6}>
                    <label>First Name</label>
                    <input
                      id="contact-first-name-input"
                      placeholder="First Name"
                    />
                  </Col>
                  <Col md={6}>
                    <label>Last Name</label>
                    <input
                      id="contact-last-name-input"
                      placeholder="Last Name"
                    />
                  </Col>
                  <Col md={6}>
                    <label>Email</label>
                    <input
                      id="contact-email-input"
                      placeholder="example@eg.com"
                    />
                  </Col>
                  <Col md={6}>
                    <label>Phone Number</label>
                    <input
                      id="contact-phone-number-input"
                      placeholder="+92 333 345X 789"
                    />
                  </Col>
                  <Col md={12}>
                    <label style={{ fontSize: "17px" }}>Select Subject?</label>
                    <div className="subject_radio">
                      <div>
                        <input
                          type="radio"
                          id="Inquiry"
                          name="subject"
                          value="Inquiry"
                        />
                        <label for="Inquiry">General Inquiry</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="Pricing"
                          name="subject"
                          value="Pricing"
                        />
                        <label for="Pricing">Pricing Info</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="Technical"
                          name="subject"
                          value="Technical"
                        />
                        <label for="Technical">Technical Support</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="Partnership"
                          name="subject"
                          value="Partnership"
                        />
                        <label for="Partnership">Business Partnership</label>
                      </div>
                    </div>
                  </Col>
                  <Col md={12}>
                    <label>Message</label>
                    <input
                      id="contact-message-input"
                      placeholder="Write your message.."
                    />
                  </Col>
                  <Col md={12}>
                    <button type="submit" id="sendMsg">
                      Send Message
                    </button>
                  </Col>
                </Row>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Contact;
