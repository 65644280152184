import React, { useEffect, useState } from "react";
import { Button, Container, Nav, Navbar } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import mainLogo from "../../images/main_logo.png";
import "./Header.css";

const NavBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (show) {
      setShow(false);
    }
  }, [location.pathname, location.hash]);

  return (
    <Navbar collapseOnSelect expand="lg" className="navbar-dark">
      <Container>
        <Navbar.Brand id="home-link" onClick={() => navigate("/")}>
          <img src={mainLogo} alt="" />
        </Navbar.Brand>
        <Navbar.Toggle id="open-close-navbar" onClick={() => setShow(!show)} />
        <Navbar.Collapse in={show}>
          <Nav className="ms-auto">
            <Nav.Link
              id="home-navigate"
              onClick={() => navigate("/")}
              className={
                location.pathname === "/" ? "text-light active" : "text-light"
              }
            >
              Home
            </Nav.Link>
            <Nav.Link
              id="solution-navigate"
              onClick={() => navigate("/solutions")}
              className={
                location.pathname === "/solutions"
                  ? "text-light active"
                  : "text-light"
              }
            >
              Solutions
            </Nav.Link>
            <Nav.Link
              id="pricing-navigate"
              onClick={() => navigate("/pricing")}
              className={
                location.pathname === "/pricing"
                  ? "text-light active"
                  : "text-light"
              }
            >
              Pricing
            </Nav.Link>
            <Nav.Link
              id="contact-navigate"
              onClick={() => navigate("/contact")}
              className={
                location.pathname === "/contact"
                  ? "text-light active"
                  : "text-light"
              }
            >
              Contact Us
            </Nav.Link>
            <Nav.Link
              id="about-navigate"
              onClick={() => navigate("/about")}
              className={
                location.pathname === "/about"
                  ? "text-light active"
                  : "text-light"
              }
            >
              About Us
            </Nav.Link>
          </Nav>
          <Button
            id="pay-parking-navigate"
            onClick={() => navigate("/pay-parking")}
          >
            Pay for Parking
          </Button>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
export default NavBar;
