import React, { useEffect, useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import CreditCardImg from "../../images/credit_card.png";
import ScratchCardImg from "../../images/scratch_card.png";
import SratchCardBackImg from "../../images/scratch_card_back.png";
import {
  PaymentSuccess,
  PaymentWithCard,
  ScratchCardVerify,
} from "../../Redux/Action/Payment";
import "./PayParking.css";
import { errorNotify, successNotify } from "../../Util/Toast";
import { useNavigate } from "react-router-dom";

const ChoosePayment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [paymentMethod, setPaymentMethod] = useState({
    credit: false,
    scratchCard: false,
  });
  const [getTicketValues, setGetTicketValues] = useState({});
  const [scratchCardValue, setScratchCardValue] = useState("");
  const [saveScratchCard, setSaveScratchCard] = useState([]);

  const { getTicketInfoData } = useSelector((state) => state.ticketInfoGet);
  const { loading, error, getScratchCardVerifyData } = useSelector(
    (state) => state.scratchCardVerifyGet
  );
  const {
    loading: paymentLoading,
    error: paymentError,
    getPaymentSuccessData,
  } = useSelector((state) => state.paymentSuccessGet);
  const {
    loading: cardLoading,
    error: cardError,
    getPaymentWithCardData,
  } = useSelector((state) => state.paymentWithCardGet);

  useEffect(() => {
    if (getPaymentWithCardData?.response === "success") {
      window.open(getPaymentWithCardData?.url);
      dispatch({ type: "PAYMENT_WITH_DEBIT_CARD_RESET" });
    }
    if (cardError) {
      errorNotify(cardError);
      dispatch({ type: "PAYMENT_WITH_DEBIT_CARD_RESET" });
    }
  }, [getPaymentWithCardData]);

  const options = [
    { value: "credit", label: "Credit/Debit Card" },
    { value: "scratchCard", label: "Scratch Card" },
  ];

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#152D5A" : "#152D5A",
      backgroundColor: state.isSelected ? "#FDFDFD" : "#FDFDFD",
      "&:hover": {
        backgroundColor: "lightblue",
      },
      fontWeight: 700,
      borderBottom: "1px solid #EFEFEF",
      cursor: "pointer",
    }),
  };

  const selectPaymentHandler = (e) => {
    if (e.value === "credit") {
      setPaymentMethod({
        scratchCard: false,
        credit: true,
      });
    } else {
      setPaymentMethod({
        scratchCard: true,
        credit: false,
      });
    }
  };

  useEffect(() => {
    if (getPaymentSuccessData?.response === "success") {
      successNotify(getPaymentSuccessData?.message);
      dispatch({ type: "PAYMENT_SUCCESS_RESET" });
      dispatch({ type: "GET_TICKET_INFO_RESET" });
      navigate("/pay-parking");
      return;
    }
    if (paymentError) {
      errorNotify(paymentError);
      dispatch({ type: "PAYMENT_SUCCESS_RESET" });
    }
  }, [getPaymentSuccessData]);

  useEffect(() => {
    if (parseInt(getTicketValues?.ticketInfo?.amountPayable) === 0) {
      const uniqueScratchCards = [...new Set(saveScratchCard)];
      const scratchCardString = uniqueScratchCards.join(",");

      const formData = new FormData();
      formData.append("codes", scratchCardString);
      formData.append(
        "ticketNumber",
        getTicketValues?.ticketInfo?.ticketNumber
      );

      dispatch(PaymentSuccess(formData));
    }
  }, [getTicketValues]);

  useEffect(() => {
    if (getTicketInfoData) {
      setGetTicketValues(getTicketInfoData);
    } else if (!getTicketInfoData) {
      navigate("/pay-parking");
      return;
    }
  }, [getTicketInfoData]);

  useEffect(() => {
    if (getScratchCardVerifyData?.response === "success") {
      setGetTicketValues({
        ...getTicketValues,
        ticketInfo: {
          ...getTicketValues.ticketInfo,
          amountPayable:
            parseInt(getTicketValues.ticketInfo.amountPayable) -
            parseInt(getScratchCardVerifyData?.amount),
        },
      });
      setSaveScratchCard(() => [...saveScratchCard, scratchCardValue]);
      successNotify(getScratchCardVerifyData?.message);

      dispatch({ type: "SCRATCH_CARD_VERIFY_RESET" });
    }
    if (error) {
      errorNotify(error);
      dispatch({ type: "SCRATCH_CARD_VERIFY_RESET" });
    }
  }, [error, getScratchCardVerifyData]);

  const scratchCardHandler = (e) => {
    e.preventDefault();

    if (scratchCardValue.length === 0) {
      errorNotify("Please enter scratch card number.");
      return;
    } else if (saveScratchCard.includes(scratchCardValue)) {
      errorNotify("You have already used this scratch card.");
      return;
    }

    let params = {
      code: scratchCardValue,
    };

    dispatch(ScratchCardVerify(params));
  };

  const debitCardHandler = (e) => {
    e.preventDefault();

    let queryParams = {
      parkingTokenNumber: getTicketValues?.ticketInfo?.ticketNumber,
    };

    if (getTicketValues?.ticketInfo?.ticketNumber) {
      dispatch(PaymentWithCard(queryParams));
    }
  };

  return (
    <div className="pay_parking_main">
      <Container>
        <h2>
          Unlock Your Parking Spot - <span>Pay Now</span>
        </h2>
        <h6>
          To pay for your parking using a scratch card, please follow these
          steps:
        </h6>

        <div className="parking_wrapper">
          <Row>
            <Col md={5}>
              <div className="left_div">
                <div>
                  <h4>
                    Pay for your <br /> <span>Parking Spot</span>{" "}
                  </h4>

                  <h5>
                    <span>Choose Payment Method</span> <br /> We offer two
                    payment methods.{" "}
                  </h5>
                  {paymentMethod.scratchCard ? (
                    <div>
                      <h5>
                        <span>Pay via Scratch Card</span>
                      </h5>
                      <p>
                        To proceed your payment enter mentioned scratch card
                        number
                      </p>
                      <img
                        src={ScratchCardImg}
                        width={250}
                        height={150}
                        alt=""
                      />
                      <img
                        src={SratchCardBackImg}
                        width={250}
                        height={150}
                        alt=""
                      />
                    </div>
                  ) : (
                    <div>
                      <h5>
                        <span>Pay via Credit Card / Debit Card</span>
                      </h5>
                      <p>Proceed you payment using Debit/Credit Card.</p>
                      <img
                        src={CreditCardImg}
                        width={250}
                        height={150}
                        alt=""
                      />
                    </div>
                  )}
                </div>
              </div>
            </Col>
            <Col md={7}>
              <div className="right_div">
                <h4>Pay for Parking</h4>

                <p>
                  Ticket Number:{" "}
                  <b>{getTicketValues?.ticketInfo?.ticketNumber}</b>
                </p>
                <p>
                  Payable Amount:{" "}
                  <b>{getTicketValues?.ticketInfo?.amountPayable} Rs</b>
                </p>
                <p>
                  Time:{" "}
                  <b>
                    <span style={{ marginRight: "8px" }}>
                      {getTicketValues?.ticketInfo?.days} Days
                    </span>
                    <span style={{ marginRight: "8px" }}>
                      {getTicketValues?.ticketInfo?.hours} Hours
                    </span>
                    {getTicketValues?.ticketInfo?.minutes} Minutes
                  </b>
                </p>

                {paymentLoading ? (
                  <div className="d-flex flex-column align-items-center justify-content-center">
                    <h5>Payment verifying</h5>
                    <Spinner />
                  </div>
                ) : (
                  <>
                    <form className="mt-4">
                      <div>
                        <label>Select Payment Method</label>
                        <Select
                          id="payment-method-select"
                          options={options}
                          styles={customStyles}
                          onChange={selectPaymentHandler}
                          className="mt-2"
                        />
                      </div>
                    </form>
                    {paymentMethod.scratchCard && (
                      <form onSubmit={scratchCardHandler}>
                        <div className="ticket_no_div">
                          <label>Scratch Card Number:</label>
                          <input
                            id="scratch-card-number-input"
                            placeholder="Enter Scratch Card Number"
                            value={scratchCardValue}
                            onChange={(e) =>
                              setScratchCardValue(e.target.value)
                            }
                          />
                          <button
                            id="scratch-card-submit-btn"
                            type="submit"
                            className="mt-3"
                          >
                            {" "}
                            {loading ? <Spinner size="sm" /> : "Pay Now"}
                          </button>
                        </div>
                      </form>
                    )}

                    {paymentMethod.credit && (
                      <form onSubmit={debitCardHandler}>
                        <div className="ticket_no_div">
                          <button
                            id="payment-method-submit-btn"
                            type="submit"
                            className="mt-3"
                          >
                            {" "}
                            {cardLoading ? (
                              <Spinner size="sm" />
                            ) : (
                              "Pay With Card Now"
                            )}
                          </button>
                        </div>
                      </form>
                    )}
                  </>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};
export default ChoosePayment;
