import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import TicketImg from "../../images/ticket_img.png";
import { errorNotify } from "../../Util/Toast";
import "./PayParking.css";
import { useDispatch, useSelector } from "react-redux";
import { GetTicketInfo } from "../../Redux/Action/Payment";
import { IoIosCamera } from "react-icons/io";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { Html5Qrcode } from "html5-qrcode";
import { useNavigate } from "react-router-dom";

const PayParking = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [stream, setStream] = useState(false);
  const [data, setData] = useState(null);
  const scannerRef = useRef(null);
  const cameraId = useRef(null);

  useEffect(() => {
    if (!stream) return;

    const initScanner = async () => {
      try {
        const devices = await Html5Qrcode.getCameras(); // Get available cameras
        if (devices.length === 0) {
          console.error("No camera found");
          return;
        }
        cameraId.current = devices[0].id; // Use the first camera
        const scanner = new Html5Qrcode("qr-reader", { verbose: false });

        await scanner.start(
          cameraId.current,
          {
            fps: 60,
            qrbox: { width: 200, height: 200 },
            aspectRatio: 1,
            disableFlip: true,
          },
          (decodedText, decodedResult) => {
            setData(decodedResult.result);
            // console.log(decodedResult.result);
          },
          (errorMessage) => {
            console.warn(errorMessage); // Ignore scan errors
          }
        );

        scannerRef.current = scanner;
      } catch (error) {
        console.error("Camera error:", error);
      }
    };

    if (stream) {
      initScanner();
    }

    return () => {
      scannerRef.current?.stop();
    };
  }, [stream]);

  const { loading, getTicketInfoData, error } = useSelector(
    (state) => state.ticketInfoGet
  );

  useEffect(() => {
    if (data) {
      setStream(false);
      let getData = data.text;
      dispatch(GetTicketInfo(getData));
    }
  }, [data]);

  useEffect(() => {
    if (getTicketInfoData?.message === "success") {
      navigate("/payment-method");
    }
  }, [getTicketInfoData]);

  // const ticketHandler = (e) => {
  //   e.preventDefault();

  //   if (ticketNo.length === 0) {
  //     errorNotify('Please enter ticket No.')
  //     return;
  //   }

  //   let getData = ticketNo
  //   dispatch(GetTicketInfo(getData))

  // }

  useEffect(() => {
    if (error) {
      setStream(false);
      errorNotify(error || "Internal Server Error");
      dispatch({ type: "GET_TICKET_INFO_RESET" });
    }
  }, [error]);

  return (
    <div className="pay_parking_main">
      <Container>
        <h2>
          Unlock Your Parking Spot - <span>Pay Now</span>
        </h2>
        <h6>
          To pay for your parking using a scratch card, please follow these
          steps:
        </h6>

        <div className="parking_wrapper">
          <Row>
            <Col md={5}>
              <div className="left_div">
                <div>
                  <h4>
                    Pay for your <br /> <span>Parking Spot</span>{" "}
                  </h4>
                  <h5>
                    <span>Enter Ticket Number</span> <br /> mentioned on your
                    parking ticket{" "}
                  </h5>
                  <img src={TicketImg} alt="" />
                </div>
              </div>
            </Col>
            <Col md={7}>
              <div className="right_div">
                {loading ? (
                  <div className="d-flex flex-column align-items-center justify-content-center">
                    <h5>Uploading Ticket Scan Data</h5>
                    <Spinner />
                  </div>
                ) : (
                  <>
                    <h4>Pay for Parking</h4>
                    <p>
                      To complete your parking payment, please provide the
                      necessary information in the fields below.
                    </p>

                    {stream && (
                      <div className="bar_code_box">
                        <div id="qr-reader" />
                        {/* <BarcodeScannerComponent
                          onUpdate={(err, result) => {
                            if (result) setData(result);
                          }}
                        /> */}
                      </div>
                    )}

                    <div className="ticket_no_div">
                      {!stream ? (
                        <button
                          id="scan-ticket-btn"
                          onClick={() => setStream(true)}
                        >
                          Scan Ticket <IoIosCamera />
                        </button>
                      ) : (
                        <button
                          id="stop-scan-ticket-btn"
                          onClick={() => setStream(false)}
                        >
                          Stop Scan Ticket <IoIosCamera />
                        </button>
                      )}
                    </div>

                    {/* <h5>OR</h5> */}

                    {/* <form onSubmit={ticketHandler}>
                  <div className='ticket_no_div mt-0'>
                    <label>Enter Ticket Number</label>
                    <input id="ticket-num-input" placeholder='TNC-XXXXX' value={ticketNo} onChange={(e) => setTicketNo(e.target.value)} />
                    <button id="ticket-num-submit-btn" type='submit' className='mt-4'>{loading ? <Spinner size='sm' /> : "Pay Now"}</button>
                  </div>
                </form> */}
                  </>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};
export default PayParking;
