import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import fbIcon from "../../images/facebook_icon.png";
import instaIcon from "../../images/insta_icon.png";
import FooterLogo from "../../images/main_logo.png";
import twitterIcon from "../../images/twitter_icon.png";
import youtubeIcon from "../../images/youtube_icon.png";
import { sendIcon } from "../../Util/Svgs";
import "./Footer.css";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <div className="footer_main">
      <Container>
        <Row className="align-items-end">
          <Col md={6}>
            <img src={FooterLogo} alt="" className="footer_logo" />
            <p>Copyright © 2024 AJCL Pvt Ltd.</p>
            <p>All rights reserved</p>

            <div className="social_media">
              <a href="" id="fb-link" target="_blank">
                {" "}
                <img src={fbIcon} alt="" />{" "}
              </a>
              <a href="" id="insta-link" target="_blank">
                {" "}
                <img src={instaIcon} alt="" />{" "}
              </a>
              <a href="" id="twitter-link" target="_blank">
                {" "}
                <img src={twitterIcon} alt="" />{" "}
              </a>
              <a href="" id="youtube-link" target="_blank">
                {" "}
                <img src={youtubeIcon} alt="" />{" "}
              </a>
            </div>
          </Col>
          <Col md={6}>
            <div className="footer_right_section">
              <Row>
                <Col md={3}>
                  <h6>Company</h6>
                  <ul>
                    <li
                      id="company-about-link"
                      onClick={() => navigate("/about")}
                    >
                      About us
                    </li>
                    <li
                      id="company-pricing-link"
                      onClick={() => navigate("/pricing")}
                    >
                      Pricing
                    </li>
                    <li
                      id="company-contact-link"
                      onClick={() => navigate("/contact")}
                    >
                      Contact us
                    </li>
                    <li
                      id="company-solutions-link"
                      onClick={() => navigate("/solutions")}
                    >
                      Solutions
                    </li>
                  </ul>
                </Col>
                <Col md={4}>
                  <h6>Support</h6>
                  <ul>
                    <li
                      id="support-pricing-link"
                      onClick={() => navigate("/pricing")}
                    >
                      Pricing
                    </li>
                    <li
                      id="support-terms-conditions-link"
                      onClick={() => navigate("/terms-condition")}
                    >
                      Terms of service
                    </li>
                    <li
                      id="support-payment-link"
                      onClick={() => navigate("/pay-parking")}
                    >
                      Pay Parking
                    </li>
                  </ul>
                </Col>
                <Col md={5}>
                  <h6>Stay up to date</h6>
                  <div className="up_to_date">
                    <input
                      id="updates-email-input"
                      placeholder="Your email address"
                    />
                    <div>{sendIcon}</div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
