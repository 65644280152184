import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import bikeImg from "../../images/bike_img.png";
import busImg from "../../images/bus_img.png";
import carImg from "../../images/car_img.png";
import vanImg from "../../images/van_img.png";
import {
  bikeDarkIcon,
  bikeLightIcon,
  busDarkIcon,
  busLightIcon,
  carDarkIcon,
  carLightIcon,
  vanDarkIcon,
  vanLightIcon,
} from "../../Util/Svgs";
import "./HeroSection.css";

const HeroSection = () => {
  const navigate = useNavigate();
  const [currentImg, setCurrentImg] = useState(0);
  const [preloadedImages, setPreloadedImages] = useState([]);

  const imageSources = [carImg, bikeImg, vanImg, busImg];

  useEffect(() => {
    const preloadImages = imageSources.map((src) => {
      const img = new Image();
      img.src = src;
      return img;
    });
    setPreloadedImages(preloadImages);
  }, []);

  return (
    <div className="hero-section">
      <Container>
        <Row className="align-items-center">
          <Col md={6}>
            <h5>Revolutionizing the</h5>
            <h1>Parking Experience</h1>
            <h5>Innovative, Quick, and Digital Solutions! </h5>
            <p>
              Smart technology is reshaping how we park, making the process
              faster, more efficient, and entirely digital.
            </p>
            <Button id="contacts-link-btn" onClick={() => navigate("/contact")}>
              Connect with Us
            </Button>
          </Col>
          <Col md={6}>
            <div>
              <div className="hero_section_img">
                {preloadedImages.length > 0 && (
                  <img
                    src={preloadedImages[currentImg].src}
                    alt="Loading..."
                    loading="lazy"
                  />
                )}
              </div>
              <div className="hero_tabs">
                <div
                  id="image-car-change-btn"
                  className={currentImg === 0 ? "active" : ""}
                  onClick={() => setCurrentImg(0)}
                >
                  {currentImg === 0 ? carDarkIcon : carLightIcon}
                  <h6>Car</h6>
                </div>
                <div
                  id="image-bike-change-btn"
                  className={currentImg === 1 ? "active" : ""}
                  onClick={() => setCurrentImg(1)}
                >
                  {currentImg === 1 ? bikeDarkIcon : bikeLightIcon}
                  <h6>Bike</h6>
                </div>
                <div
                  id="image-van-change-btn"
                  className={currentImg === 2 ? "active" : ""}
                  onClick={() => setCurrentImg(2)}
                >
                  {currentImg === 2 ? vanDarkIcon : vanLightIcon}
                  <h6>Van</h6>
                </div>
                <div
                  id="image-bus-change-btn"
                  className={currentImg === 3 ? "active" : ""}
                  onClick={() => setCurrentImg(3)}
                >
                  {currentImg === 3 ? busDarkIcon : busLightIcon}
                  <h6>Bus</h6>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HeroSection;
